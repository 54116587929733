import React from 'react';






class Footer extends React.Component{
    render(){
        return(
            <React.Fragment>
            <section className="p-1 bg-dark text-white mt-1 fixed-bottom">
                    <div className="container">
                    
                            <div className="col text-center">
                                
                                <h4 class="h4-responsive font-weight-bold text-center ">© 2020 Copyright SSSPL</h4>  
                            </div>
                        </div>
                </section>
            </React.Fragment>
        )
    }
    
}

export default Footer;