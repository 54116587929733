import React from "react";
import Axios from "axios";
import {Link} from 'react-router-dom';

class Employees extends React.Component{
  render(){
      return(<React.Fragment>

                <div>
                   
                </div>
            </React.Fragment>
        )
  }
    }

export default Employees;
