import React from "react";
import card1 from '../assets/img/card_1.jpg';
import card2 from '../assets/img/card_2.jpg';
import card3 from '../assets/img/card_3.jpg';
import card4 from '../assets/img/card_4.jpg';
import CountryCard from "./CountryCard";

class CountryCards extends React.Component{
    render() {
        let countries = ['Tirupathi' , 'Sri Kalahasthi' , 'Chittoor' , 'Malaysia'];
        return (
            <React.Fragment>
                <div className="container mt-3 align-center">
                    <div className="row">
                        <div className="col-md-3">
                            <CountryCard cardImg={card1} Address={"Serviced Apartment, 18-12-1710, Shanthi Nagar Rd, Shanthi Nagar, Khadi Colony, Tirupati, Andhra Pradesh 517501"} countryName={countries[0]}/>
                        </div>
                        <div className="col-md-3">
                            <CountryCard cardImg={card2} Address={" 3, 993, Nagaristreeet, Bahadur Pet, Srikalahasti, Andhra Pradesh 517644"}  countryName={countries[1]}/>
                        </div>
                        <div className="col-md-3">
                            <CountryCard cardImg={card3} Address={"Chittoor Tirupati, Andhra Pradesh 517501"} countryName={countries[2]}/>
                        </div>
                       
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default CountryCards;
