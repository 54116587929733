import React from "react";

class Home extends React.Component{
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <React.Fragment>
                <div className="landing-page">
                    <div className="wrapper">
                        <div className="d-flex flex-column text-center justify-content-center align-items-center h-100">
                            <h3 className="display-3">Jayaram Residency</h3>
                            <p className="px-3">If what you're looking for is a conveniently located property in Srikalahasti ,Tirupathi and Chittoor look no further than Jayaram Residency.</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Home;
