import React from "react";

class About extends React.Component{
    constructor(props) {
        super(props);

    }

    render() {
        return (
        <React.Fragment>
            <div className="container mt-3">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body bg-light">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3877.570176774256!2d79.40662141431045!3d13.623043303935754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4d4bf54c9d8f07%3A0xd3c7bee907718c8a!2sJayaram%20Residency%20Tirupathi!5e0!3m2!1sen!2sin!4v1604292458480!5m2!1sen!2sin"
                                        width="500" height="350" frameBorder="0" allowFullScreen=""
                                        aria-hidden="false" tabIndex="0"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <p className="h4">Contact Us</p>
                                </div>
                                <div className="card-body bg-light">
                                    <form>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Name"/>
                                        </div>
                                        <div className="form-group">
                                            <input type="email" className="form-control" placeholder="Email"/>
                                        </div>
                                        <div className="form-group">
                                            <textarea rows="4" className="form-control"/>
                                        </div>
                                        <div>
                                            <input type="submit" className="btn btn-dark btn-sm" value="Contact"/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </React.Fragment>
        );
    }
}
export default About;
