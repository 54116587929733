import React from "react";

class CountryCard extends React.Component{

    render() {
        return (
            <React.Fragment>
                <div className="card" style={{height:"500px"}}>
                    <img src={this.props.cardImg} style={{height:"300px",width:"500px"}} className="img-fluid" alt=""/>
                    <div className="card-body">
                        <p className="h4">{this.props.countryName}</p>
                            <p>{this.props.Address}</p>
                        {/* <button className="btn btn-dark btn-sm">Read More</button> */}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default CountryCard;
